import React from 'react'
import { Link, graphql } from 'gatsby'
import Header from '../components/header';
import Img from 'gatsby-image'
import styles from '../styles/blog.module.css';

export default class extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;

    return (
      <>
        <Header />
        <div className={styles.container}>

          {posts.map(post => {
            if (post.node.path !== '/404/') {
              return (
                <div className="flex-align-center fade-down bg-light-white" key={post.node.frontmatter.path}>
                  <Link to={post.node.frontmatter.path}>
                    <div className={styles.leftContent}>
                      <h3>
                        {post.node.frontmatter.title}
                      </h3>
                      <p className="text-muted" dangerouslySetInnerHTML={{ __html: post.node.excerpt }} />
                      <div>
                        <span>
                          {post.node.frontmatter.authorName}
                        </span>
                        <div className="text-muted" style={{paddingTop: "10px"}}>
                          <span className="middot-divider">{post.node.frontmatter.dateModified}</span>
                          <span>{`${post.node.timeToRead} min read`}</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.rightImage}>
                      <Img fluid={post.node.frontmatter.image.childImageSharp.fluid} />
                    </div>
                  </Link>
                </div>
              )
            }
            return null;
          })}

        </div>
      </>
    )
  }
}

export const blogQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { eq: "blog" } } }
    ) {
        edges {
          node {
            excerpt(pruneLength: 100)
            frontmatter {
              title
              path
              dateModified(formatString: "MMM DD'YY")
              authorName
              image {
                childImageSharp {
                    fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
              }
            }
            timeToRead
          }
        }
    }
  }
`